.unselectable {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.green-stroke {
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: rgb(50, 90, 10);
}

.green-black {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.white-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
